import React from "react";
import Layout from "../components/layout";
import Sociallinks from "../components/sociallinks";
import styles from "../styles/pages.module.css";

export default function Home() {
	return (
		<Layout title="Home">
			<div className="container mt-3 page-home">
				<div className="d-flex flex-column flex-md-row align-items-center">
					<div className="col">
						<p className="self-portrait"><img src="https://firebasestorage.googleapis.com/v0/b/debjyotiacharjee-14406.appspot.com/o/Debjyoti-A.jpg?alt=media&token=28f8d644-17a3-48f2-804d-047b4021787c"></img></p>
					</div>

					<div className="col">
						<p className={styles.para}>Hi there! Welcome to my digital Home.</p>

						<p className={styles.para}>I am <span className={styles.name}>Debjyoti Acharjee</span>. Happily married, cat loving IT guy from <i>Kolkata</i> currently located in <i>Hong Kong</i>.</p>

						<p className={styles.para}>
							...likes making and breaking things.
							In my free time I like exploring new technologies and watching product reviews.
							Melophile, likes watching webseries and happily participates in movie marathons.
						</p>

						{/* <p className={styles.para}>
							If you happen to visit my city, don't forget to say hello and definitely try the <i>Rossogolla</i>.
						</p> */}

						<Sociallinks />
					</div>
				</div>
			</div>
		</Layout>
	);
};
